const login = userObj => {
    return {
        type: 'LOG_IN',
        payload: userObj,
    };
};

const logout = () => {
    return {
        type: 'LOG_OUT',
    };
};

const getUser = () => dispatch => {
    fetch('/tree/curr-user/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`
        }
    })
    .then(res => res.json())
    .then(json => {
        dispatch({type: 'GET_USER', payload: json});
    })
}

export default {
    login,
    logout,
    getUser,
};
