const visibleTreeToArray = tree => {
    const getChildren = node => {
        let res = [];
        if ('children' in node && node.toggled === true) {
            node.children.forEach(child => {
                res.push(child);
                res = res.concat(getChildren(child));
            })
        };
        return res;
    } 

    let res = [];
    tree.forEach(node => {
        res.push(node);
        res = res.concat(getChildren(node));
    });

    let activeIdx = null;
    for (let i=0; i < res.length; i++) {
        if (res[i].active === true) {
            activeIdx = i;
            break
        };
    }

    return { data: res, current: activeIdx }
}

const getNodeById = (theObject, id) => {
    let res = undefined;

    if(theObject instanceof Array) {
        for(var i = 0; i < theObject.length; i++) {
            res = getNodeById(theObject[i], id);
            if (res) return res;
        }
    }
    else
    {
        if(theObject.id === id) {
            return theObject;
        }

        if(theObject.children instanceof Object || theObject.children instanceof Array)
            res = getNodeById(theObject.children, id);
            if (res) return res;
    }

    return res;
};

const toggleAllParents = (theObject, id) => {
    let res = undefined;

    if(theObject instanceof Array) {
        for(var i = 0; i < theObject.length; i++) {
            res = toggleAllParents(theObject[i], id);
            if (res) {
                theObject[i].toggled = true;
                return res;
            }
        }
    }
    else
    {
        if(theObject.id === id) {
            theObject.selected = true;
            theObject.active = true;
            return theObject;
        }

        if(theObject.children instanceof Object || theObject.children instanceof Array)
            res = toggleAllParents(theObject.children, id);
            if (res) return res;
    }

    return res;
};

const removeFoundClass = theObject => {
    if(theObject instanceof Array) {
        for(var i = 0; i < theObject.length; i++) {
            theObject[i].data.class = '';
            removeFoundClass(theObject[i]);
        }
    }
    else {
        console.log(theObject);
        theObject.data.class = '';
        if(theObject.children instanceof Array) {
            removeFoundClass(theObject.children);
        }
    }
}

const untoggleChildren = children => {
    children.forEach(child => {
        if (child.toggled) {
            child.toggled = false;
            if (child.hasOwnProperty('children')) untoggleChildren(child.children);
        }
    })
};

const getSiblings = (data, node) => {
    function findSiblings(nodes, node) {
        let res;
        for (let i=0; i<nodes.length; i++) {
            if (nodes[i].id === node.parentId) {
                for (let j=0; j<nodes[i].children.length; j++) {
                    if (nodes[i].children[j].id === node.id) {
                        return { siblings: nodes[i].children, nodePos: j }
                    }
                }
            } else {
                if (nodes[i].toggled) {
                    res = findSiblings(nodes[i].children, node);
                    if (res) return res;
                };
            }
        }
    }

    if (!node.parentId) {
        for (let i=0; i<=data.length; i++) {
            if (data[i].id === node.id) return {siblings: data, nodePos: i, root: true};
        }
    } else {
        return findSiblings(data, node);
    }
}

const replaceAll = (string, search, replace) => {
    const res = string.split(search).join(replace);
    return res.charAt(0).toUpperCase() + res.slice(1);
}

const replaceFromData = (data, what, to) => {
    let nodesToReplace = {};
    data.forEach(el => {
        if (el.data.class === 'found') {
            nodesToReplace[el.id] = replaceAll(el.data.name.toLowerCase(), what.toLowerCase(), to);
        }
        if ('children' in el) {
            nodesToReplace = {...nodesToReplace, ...replaceFromData(el.children, what, to)};
        }
    })
    return nodesToReplace;
}

export { getNodeById, visibleTreeToArray, untoggleChildren, getSiblings, 
         replaceFromData, toggleAllParents, removeFoundClass };