import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import userActions from '../../redux/actions/UserActions';
import './Logout.css';

const Logout = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleLogout = () => {
        localStorage.removeItem('token');
        dispatch(userActions.logout());
        history.push('/login');
    }

    return (
        <Nav.Link className="Logout-link" onClick={handleLogout}>Wyloguj</Nav.Link>
    )
}

export default Logout;