import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-dropdown-select';
import PropTypes from 'prop-types';
import styles from './styles';
import { Form, Row, Col } from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';
import './NodeViewer.css';

const NodeViewer = props => {
    const [title, setTitle] = useState(props.node?.data.name);
    const [loaded, setLoaded] = useState(false);
    const [patology, setPatology] = useState({norm: false, patology: false});
    const [type, setType] = useState({numeric: false, text: false});
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [unit, setUnit] = useState([]);
    const [units, setUnits] = useState([]);
    const [text, setText] = useState('');
    const [hasValues, setHasValues] = useState(props.node.data.has_values);
    const textInput = useRef(null);
    const style = styles.viewer;

    useHotkeys('enter', () => textInput.current.focus());

    useEffect(() => {
        setLoaded(false);
        setTitle(props.node.data.name);
        fetch('/tree/get-node-details/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({node_id: props.node.id}),
        })
        .then(res => res.json())
        .then(json => { 
            if (json.patology != null) {
                json.patology ? setPatology({...patology, patology: true}) : setPatology({...patology, norm: true});
            }
            if (json.featureType != null) {
                json.featureType === 'numeric' ? setType({...type, numeric: true}) : setType({...type, text: true});
            }
            setFrom(json.from);
            setTo(json.to);
            if (json.unit != null) setUnit([{label: json.unit}]);
            const unitsObj = json.units.map((e, i) => {return {label: e}});
            setUnits(unitsObj);
            setText(json.text);
            setLoaded(true);
        })
    }, [props.node])

    useEffect(() => {
        if (loaded) {
            if ((!patology.norm && !patology.patology) &&
                (!type.numeric && !type.text) && !from && !to && !unit[0].label && !text) {
                if (hasValues) setHasValues(false);
            } else {
                if (!hasValues) setHasValues(true);
            }
        }
    }, [patology, type, from, to, unit, text]);

    useEffect(() => {
        props.onChangeHasValues(hasValues);
    }, [hasValues])

    function updateTitle() {
        props.onChangeTitle(title);
    }

    function handlePatologyChange(e) {
        let pat;
        switch (e.currentTarget.id) {
            case 'radioNorm':
                pat = false;
                setPatology({norm: true, patology: false});
                break;
            case 'radioPatology':
                pat = true;
                setPatology({norm: false, patology: true});
                break
        }

        fetch('/tree/update-patology/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({node_id: props.node.id, patology: pat}),
        });
    }

    function handleTypeChange(e) {
        let t;
        switch (e.currentTarget.id) {
            case 'radioNumeric':
                t = 'numeric';
                setType({numeric: true, text: false});
                break;
            case 'radioText':
                t = 'text';
                setType({numeric: false, text: true});
                break
            case 'radioNull':
                t = null;
                setType({numeric: false, text: false});
                break;
        }

        fetch('/tree/update-type/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({node_id: props.node.id, type: t}),
        });
    }

    function handleTextChange(e) {
        setText(e.currentTarget.value);
    }

    function handleTextUpdate() {
        fetch('/tree/update-text/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({node_id: props.node.id, text: text}),
        });
    }

    function handleFromUpdate() {
        fetch('/tree/update-from/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({node_id: props.node.id, from: from}),
        });
    }

    function handleToUpdate() {
        fetch('/tree/update-to/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({node_id: props.node.id, to: to}),
        });
    }

    function handleUnitChange(values) {
        setUnit(values);
        fetch('/tree/update-unit/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({node_id: props.node.id, unit: values[0]['label']}),
        });
    }

    function handleAddUnit(item) {
        setUnits([...units, item]);
        fetch('/tree/update-units/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({unit: item.label}),
        });
    }

    return (
        <div>
            <div style={style.base}>
            { props.node ?
            <div>
                <input 
                    className="h3"
                    ref={textInput}
                    style={style.editable} 
                    type="text" 
                    value={title} 
                    onChange={e => setTitle(e.target.value)} 
                    onBlur={updateTitle}  
                />
                <hr style={style.line} />
                { loaded
                    ? <Form className="NodeViewer-form">
                    <Row>
                        <Col>
                            <fieldset>
                                <Form.Group as={Row} className="mb-0">
                                    <Form.Label className="pt-0 pb-0" as="legend" column="lg" lg={6}>
                                        Patologia
                                    </Form.Label>
                                    <Col sm={6} className="NodeViewer-centerize">
                                        <Form.Check
                                            type="radio"
                                            label="Norma"
                                            name="radiosPatology"
                                            id="radioNorm"
                                            checked={patology.norm}
                                            onChange={handlePatologyChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Patologia"
                                            name="radiosPatology"
                                            id="radioPatology"
                                            checked={patology.patology}
                                            onChange={handlePatologyChange}
                                        />
                                    </Col>
                                </Form.Group>
                            </fieldset>
                            <fieldset>
                                <Form.Group as={Row} className="mb-0">
                                    <Form.Label className="pt-0 pb-0" as="legend" column="lg" lg={6}>
                                        Typ
                                    </Form.Label>
                                    <Col sm={6} className="NodeViewer-centerize">
                                        <Form.Check
                                            type="radio"
                                            label="Brak"
                                            name="radiosType"
                                            id="radioNull"
                                            checked={!type.numeric && !type.text}
                                            onChange={handleTypeChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Numeryczny"
                                            name="radiosType"
                                            id="radioNumeric"
                                            checked={type.numeric}
                                            onChange={handleTypeChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Tekstowy"
                                            name="radiosType"
                                            id="radioText"
                                            checked={type.text}
                                            onChange={handleTypeChange}
                                        />
                                    </Col>
                                </Form.Group>
                            </fieldset>
                        </Col>
                    </Row>
                        {
                            type.text
                            ? <Form.Group as={Col} controlId="validationFormik01" className="pl-0 pr-0">
                                <Form.Label className="pt-0 pb-0" column="lg" lg={2} className="pl-0">
                                    Wartość
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="textValue"
                                    value={text}
                                    title={text}
                                    onChange={handleTextChange}
                                    onBlur={handleTextUpdate}
                                    rows={5}
                                    className="NodeViewer-textarea"
                                />
                            </Form.Group>
                            : <></>
                        }
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label className="pt-0 pb-0">Od</Form.Label>
                                <Form.Control 
                                    className="NodeViewer-textarea" 
                                    type="number"
                                    value={from}
                                    onChange={e => { e.currentTarget.value ? setFrom(e.currentTarget.value) : setFrom(null)}}
                                    onBlur={handleFromUpdate}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridZip">
                                <Form.Label className="pt-0 pb-0">Do</Form.Label>
                                <Form.Control 
                                    className="NodeViewer-textarea" 
                                    type="number"
                                    value={to}
                                    onChange={e => { e.currentTarget.value ? setTo(e.currentTarget.value) : setTo(null)}}
                                    onBlur={handleToUpdate}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label className="pt-0 pb-0">Jednostka</Form.Label>
                                <Select
                                    create
                                    values={unit}
                                    options={units}
                                    onChange={handleUnitChange}
                                    onCreateNew={handleAddUnit}
                                    className="NodeViewer-textarea"
                                />
                            </Form.Group>
                        </Form.Row>
                    </Form>
                    : <></>
                }
            </div>
            : <></> }
            </div>
        </div>
    );
};

NodeViewer.propTypes = {
    node: PropTypes.object
};

export default NodeViewer;