function checkVisible(elm) {
    const rect = elm.getBoundingClientRect();
    const view = document.getElementsByClassName("big-tree")[0];
    const viewRect = view.getBoundingClientRect();
    return !(rect.top < viewRect.top || rect.bottom > viewRect.bottom);
}

function momentToList(date, start) {
    const arr = date.toArray();
    arr[1] += 1;
    if (start) arr[3] = 0;
    else {
        arr[3] = 23;
        arr[4] = 59;
        arr[5] = 59;
    }
    return arr;
}

export { checkVisible, momentToList };
