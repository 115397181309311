import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import userActions from '../../redux/actions/UserActions';
import LoginForm from './LoginForm';

const Authentication = props => {
    const loggedIn = useSelector((state) => state.user.loggedIn);
    const dispatch = useDispatch();
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        if (loggedIn) {
            fetch('/tree/current_user', {
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(json => {
                setUsername(json.username);
            })
        }
    }, []);

    const handleLogin = (e, data) => {
        e.preventDefault();
        fetch('/token-auth/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        })
        .then(res => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
            if (resOk) {
                localStorage.setItem('token', data.token);
                setUsername(data.user.username);
                setStatus('');
                dispatch(userActions.login(data.user));
                history.push('/');
            } else {
                setStatus('Nieprawidłowe dane logowania!');
            }
        })
    };

    return (
        <div className="Authentication">
            <LoginForm handleLogin={handleLogin} status={status}/>
        </div>
    );
}

export default Authentication;