import { EDIT_HELPER_TITLE, ADD_HELPER_ROOT, LOAD_HELPER_DATA, DELETE_HELPER_NODE } from '../types/HelperDataTypes';

export const editHelperTitle = (id, title) => dispatch => {
    dispatch({type: EDIT_HELPER_TITLE, payload: { id, title }});
    fetch('/tree/update-helper-root-title/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({id, title}),
    })
};

export const addHelperRoot = node => dispatch => {
    fetch('/tree/add-helper-root/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(node),
    })
    .then(res => res.json())
    .then(root => {
        dispatch({type: ADD_HELPER_ROOT, payload: { root }});
        console.log('Dodano node do drzewa pomocniczego!');
    })
};

export const deleteHelperNode = (id) => dispatch => {
    dispatch({ type: DELETE_HELPER_NODE, payload: { id } });
    fetch('/tree/delete-helper-node/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ id }),
    })
    .then(res => res.json())
    .then(json => {
        console.log('Usunięto node!')
    })
};

export const loadHelperData = () => dispatch => {
    fetch('/tree/get-helper-tree/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
        },
    })
    .then(res => res.json())
    .then(json => {
        dispatch({ type: LOAD_HELPER_DATA, json });
    })
};
