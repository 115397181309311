import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PublicOnlyRoute = ({ component: Component, componentProps, ...rest }) => {
    const loggedIn = useSelector((state) => state.user.loggedIn);
    
    return(
        <Route {...rest} render={() => (
            loggedIn
            ? <Redirect to='/' />
            : <Component {...componentProps} />
        )} />
    )
}

export default PublicOnlyRoute;
