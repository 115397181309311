import React, { useState, useEffect } from 'react';
import { includes } from 'lodash';
import { Treebeard, decorators } from 'react-treebeard';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { visibleTreeToArray, untoggleChildren, getSiblings } from '../../utils/traverseTree';
import { deleteHelperNode } from '../../redux/actions/HelperDataActions';
import Header from './HelperHeader';
import styles from '../styles';
import '../tree/Tree.css';

const HelperTree = props => { 
    const [data, setData] = useState(_.cloneDeep(props.data));
    const [rootIds, setRootIds] = useState(props.data.map(e => e.id));
    const [arrayData, setArrayData] = useState({ data: undefined, current: undefined });
    const [cursor, setCursor] = useState(undefined);
    const [cursorSiblings, setCursorSiblings] = useState(undefined);

    const dispatch = useDispatch();

    useEffect(() => {  
        setData(_.cloneDeep(props.data));
        setRootIds(props.data.map(e => e.id));
        setCursorSiblings(undefined);
    }, [props.data]);

    useEffect(() => {
        if (data) setArrayData({...arrayData, ...visibleTreeToArray(data)});
    }, [data])

    function onToggle(node, toggled) {
        if (node.children) {
            node.toggled = toggled;
            if (!toggled) {
                untoggleChildren(node.children);
            }
        }

        setData([...data]);
    }

    function onSelect(node) {
        if (cursor) {
            cursor.active = false;
            if (!includes(cursor.children, node)) {
                cursor.selected = false;
            }
        }

        node.selected = true;
        node.active = true;

        setData([...data]);
        setCursor(node);
        if (rootIds.includes(node.id)) setCursorSiblings(getSiblings(data, node)); 
        else setCursorSiblings(undefined);       
    }

    function handleAddToMainTree() {
        props.handleAddToMainTree(cursor);
    }

    function handleDeleteNode() {
        const rootIds = data.map((e, i) => e.id);
        if (rootIds.includes(cursor.data.helperId)) {
            if(window.confirm(`Czy na pewno chcesz usunąć '${cursor.data.name}'?`)) {
                dispatch(deleteHelperNode(cursor.data.helperId));
                setCursor(undefined);
                setCursorSiblings(undefined);
            }
        } else {
            alert('Możesz usuwać tylko cechy na najwyższym poziomie!');
        }
    }

    function handleMoveNodeUp() {
        if (cursorSiblings.nodePos > 0) {
            fetch('/tree/swap-nodes/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ 
                    nodeId1: cursorSiblings.siblings[cursorSiblings.nodePos - 1].id, 
                    nodeId2: cursorSiblings.siblings[cursorSiblings.nodePos].id,
                    helper: true
                }),
            });
            const temp = data[cursorSiblings.nodePos - 1];
            data[cursorSiblings.nodePos - 1] = data[cursorSiblings.nodePos];
            data[cursorSiblings.nodePos] = temp;

            const temp1 = cursorSiblings.siblings[cursorSiblings.nodePos - 1];
            cursorSiblings.siblings[cursorSiblings.nodePos - 1] = cursorSiblings.siblings[cursorSiblings.nodePos];
            cursorSiblings.siblings[cursorSiblings.nodePos] = temp1;

            cursorSiblings.nodePos -= 1;
            setData([...data]);
        }
    }

    function handleMoveNodeDown() {
        if (cursorSiblings.nodePos < cursorSiblings.siblings.length - 1) {
            fetch('/tree/swap-nodes/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ 
                    nodeId1: cursorSiblings.siblings[cursorSiblings.nodePos].id, 
                    nodeId2: cursorSiblings.siblings[cursorSiblings.nodePos + 1].id,
                    helper: true
                }),
            });
            const temp = data[cursorSiblings.nodePos + 1];
            data[cursorSiblings.nodePos + 1] = data[cursorSiblings.nodePos];
            data[cursorSiblings.nodePos] = temp;

            const temp1 = cursorSiblings.siblings[cursorSiblings.nodePos + 1];
            cursorSiblings.siblings[cursorSiblings.nodePos + 1] = cursorSiblings.siblings[cursorSiblings.nodePos];
            cursorSiblings.siblings[cursorSiblings.nodePos] = temp1;
            cursorSiblings.nodePos += 1;

            setData([...data]);
        }
    } 

    return (
        data 
        ? 
        <div className="mt-2">
            <Button variant="dark" size="sm" className="mb-2 mt-0 ml-1" onClick={handleAddToMainTree} disabled={!cursorSiblings}>Wstaw</Button>
            <Button variant="dark" size="sm" className="mb-2 mt-0 ml-1" onClick={handleDeleteNode} disabled={!cursorSiblings}>Usuń</Button>
            {!!cursorSiblings && <Button variant="dark" size="sm" className="mb-2 mt-0 ml-1" title="Alt+Up" onClick={handleMoveNodeUp}>
                    <FontAwesomeIcon 
                        icon={faArrowUp} 
                        size="lg" 
                    />
                </Button>}
            {!!cursorSiblings && <Button variant="dark" size="sm" className="mb-2 mt-0 ml-1" title="Alt+Down" onClick={handleMoveNodeDown}>
                <FontAwesomeIcon 
                    icon={faArrowDown} 
                    size="lg" 
                />
            </Button>}
            <div className="p-0" style={{...styles.component, width: '100%'}}>
                <div className="helper-tree">
                    <Treebeard
                        data={data}
                        onToggle={onToggle}
                        onSelect={onSelect}
                        decorators={{...decorators, Header}}
                    />
                </div>
            </div>
        </div> 
        : <></>
    );
}

export default HelperTree;
