import React from 'react';
import { getHeader } from '../../utils/filterTree';

import './Header.css';

const Header = (props) => {
    return (
        <div className="Header-outer" onClick={props.onSelect}>
            <div className="Header-inner" id={`node-${props.node.id}`}>
                {/* { props.node.data?.class === 'found' 
                ? <div className="found">{props.node.data.name}</div> 
                : props.node.data.name} */}
                { getHeader(props.node) }
            </div>
        </div>
    );
};

export default Header;
