import React from 'react';
import CustomInput from './CustomInput';

import '../tree/Header.css';

const HelperHeader = (props) => {
    return (
        <div className="Header-outer" onClick={props.onSelect}>
            <div className="Header-inner">
                {props.node.selected && props.node.data.root 
                ? <CustomInput nodeId={props.node.id} key={props.node.id} value={props.node.data.name}/> 
                : props.node.data.name }
            </div>
        </div>
    );
};

export default HelperHeader;
