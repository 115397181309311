export default {
    component: {
        display: 'inline-block',
        verticalAlign: 'top',
        padding: '20px',
        '@media (maxWidth: 640px)': {
            width: '100%',
            display: 'block'
        }
    },
    searchBox: {
        padding: '20px 20px 0 20px'
    },
    viewer: {
        base: {
            fontSize: '12px',
            whiteSpace: 'pre-wrap',
            backgroundColor: '#282C34',
            border: 'solid 1px black',
            padding: '20px',
            color: '#9DA5AB',
            minHeight: '250px'
        },
        line: {
            backgroundColor: '#8a8a8a',
            height: 1
        },
        editable: {
            backgroundColor: 'transparent',
            border: 'none',
            color: '#fff',
            width: '100%',
        }
    },
};