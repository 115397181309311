import _ from 'lodash';
import { EDIT_HELPER_TITLE, ADD_HELPER_ROOT, LOAD_HELPER_DATA, DELETE_HELPER_NODE } from '../types/HelperDataTypes';

export const initialState = [];

const helperDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_HELPER_DATA:
            return action.json;
        case EDIT_HELPER_TITLE:
            const cp = _.cloneDeep(state);
            for (let i=0; i<=cp.length; i++) {
                if (cp[i].id === action.payload.id) {
                    cp[i].data.name = action.payload.title;
                    break;
                }
            }
            return cp;
        case ADD_HELPER_ROOT:
            return [action.payload.root, ...state];
        case DELETE_HELPER_NODE:
            let i = 0;
            for (i; i <= state.length; i++) {
                if (state[i].id === action.payload.id) break;
            };
            const copy = _.cloneDeep(state);
            copy.splice(i, 1);
            return copy;
    }
    return state;
};

export default helperDataReducer;
