import { combineReducers } from 'redux';
import dataReducer from './DataReducer';
import userReducer from './UserReducer';
import helperDataReducer from './HelperDataReducer';
import listeningReducer from './ListeningReducer';

const rootReducer = combineReducers({
    data: dataReducer,
    user: userReducer,
    helperData: helperDataReducer,
    listening: listeningReducer
});

export default rootReducer;
