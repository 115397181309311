import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editHelperTitle } from '../../redux/actions/HelperDataActions';

const CustomInput = props => {
    const [text, setText] = useState(props.value);
    const dispatch = useDispatch();

    function handleChangeTitle() {
        dispatch(editHelperTitle(props.nodeId, text));
    }

    return (
        <input 
            className="CustomInput-input" 
            type="text" 
            value={text} 
            onChange={e => setText(e.currentTarget.value)}
            onBlur={handleChangeTitle}
        />
    )
}

export default CustomInput;
