const initialState = {
    loggedIn: localStorage.getItem('token') ? true : false,
    currentUser: {},
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_IN':
            return { ...state, loggedIn: true, currentUser: action.payload };
        case 'LOG_OUT':
            return { ...state, loggedIn: false, currentUser: {} };
        case 'GET_USER':
            return { ...state, currentUser: action.payload };
        default:
    }

    return state;
}

export default userReducer;
