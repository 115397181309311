import { COPY_NODE, EDIT_TITLE, EDIT_DESCRIPTION, ADD_NODE, ADD_NODE_AS_SIBLING, DELETE_NODE, LOAD_DATA, RENAME_NODES } from '../types/DataTypes';
import { addNode, addNodeAsSibling, copyNode, deleteNode, updateTitle } from '../../utils/updateTree';

export const initialState = {data: undefined, lastId: undefined};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_DATA:
            return {data: action.json, lastId: undefined};
        case COPY_NODE:
            return {data: copyNode(state.data, action.payload.data, action.payload.targetId), lastId: action.payload.newId};
        case EDIT_TITLE:
            return {data: updateTitle(state.data, action.payload.id, action.payload.title), lastId: action.payload.id};
        case EDIT_DESCRIPTION:
            return state;
        case ADD_NODE:
            return {data: addNode(state.data, action.payload.parentId, action.payload.newId), lastId: action.payload.newId};
        case ADD_NODE_AS_SIBLING:
            return {data: addNodeAsSibling(state.data, action.payload.siblingId, action.payload.newId), lastId: action.payload.newId};
        case DELETE_NODE:
            return {data: deleteNode(state.data, action.payload.id), lastId: undefined};
        case RENAME_NODES:
            return {data: action.json, lastId: undefined}
    }
    return state;
};

export default dataReducer;
