import _ from 'lodash';


const updateTitle = (data, id, title) => {
    const dataCopy = _.cloneDeep(data);
    editObject(dataCopy, id, {name: title});
    return dataCopy;
}

const addNode = (data, parentId, newId) => {
    const dataCopy = _.cloneDeep(data);
    findAndAddNode(dataCopy, parentId, newId);
    return dataCopy;
}

const addNodeAsSibling = (data, siblingId, newId) => {
    const dataCopy = _.cloneDeep(data);
    findAndAddNodeAsSibling(dataCopy, siblingId, newId);
    return dataCopy;
}

const deleteNode = (data, id) => {
    const dataCopy = _.cloneDeep(data);
    findAndDeleteNode(dataCopy, id);
    return dataCopy;
}

const copyNode = (data, newNode, targetId) => {
    const dataCopy = _.cloneDeep(data);
    findAndAddNode(dataCopy, targetId, undefined, newNode );
    return dataCopy;
}

const editObject = (theObject, id, changes) => {
    if(theObject instanceof Array) {
        for(var i = 0; i < theObject.length; i++) {
            editObject(theObject[i], id, changes);
        }
    }
    else
    {
        if(theObject.id === id) {
            for(var change in changes) {
                theObject['data'][change] = changes[change];
            }
            return;
        }

        if(theObject.children instanceof Object || theObject.children instanceof Array)
            editObject(theObject.children, id, changes, true);
    }
}


const findAndAddNode = (theObject, parentId, newId=undefined, newNode=undefined) => {
    if(theObject instanceof Array) {
        for(var i = 0; i < theObject.length; i++) {
            findAndAddNode(theObject[i], parentId, newId, newNode);
        }
    }
    else
    {
        if(theObject.id === parentId) {
            theObject['toggled'] = true;
            if (!newNode) {
                newNode = {
                    data: {'name': 'Nowy'},
                    id: newId,
                    toggled: true,
                    parentId: parentId,
                };

                if('children' in theObject) {
                    theObject['children'].push(newNode)
                } else {
                    theObject['children'] = [newNode];
                }
            } else {
                if('children' in theObject) {
                    theObject['children'] = theObject['children'].concat(newNode);
                } else {
                    theObject['children'] = newNode;
                }
            }
            return;
        }

        if(theObject.children instanceof Object || theObject.children instanceof Array)
            findAndAddNode(theObject.children, parentId, newId, newNode);
    }
}


const findAndAddNodeAsSibling = (theObject, siblingId, newId=undefined, newNode=undefined) => {
    let isSibling = false;
    if(theObject instanceof Array) {
        for(var i = 0; i < theObject.length; i++) {
            isSibling = findAndAddNodeAsSibling(theObject[i], siblingId, newId, newNode);
            if (isSibling) theObject.splice(i+1, 0, {data: {name: 'Nowy'}, id: newId, parentId: theObject[0].parentId});
        }
    }
    else
    {
        if(theObject.id === siblingId) {
            return true;
        }

        if(theObject.children instanceof Object || theObject.children instanceof Array)
            isSibling = findAndAddNodeAsSibling(theObject.children, siblingId, newId, newNode);
    }
    return isSibling;
}

const findAndDeleteNode = (theObject, id, parentObject=undefined) => {
    let isParent = false;
    if(theObject instanceof Array) {
        for(var i = 0; i < theObject.length; i++) {
            isParent = findAndDeleteNode(theObject[i], id);
            if(isParent) {
                const index = theObject.findIndex(element => element.id === id);
                theObject.splice(index, 1);
                if (theObject.length === 0) delete parentObject['children'];
                return;
            }
        }
    }
    else
    {
        if(theObject.id === id) {
            return true;
        }

        if(theObject.children instanceof Object || theObject.children instanceof Array)
            isParent = findAndDeleteNode(theObject.children, id, theObject);
            if(isParent) {
                const index = theObject.children.findIndex(element => element.id === id);
                theObject.children.splice(index, 1);
                if (theObject.children.length === 0) delete theObject['children'];
                return;
            }

    }
    return isParent;
}

export { addNode, addNodeAsSibling, copyNode, deleteNode, updateTitle };
