import { COPY_NODE, EDIT_TITLE, EDIT_DESCRIPTION, ADD_NODE, ADD_NODE_AS_SIBLING, DELETE_NODE, LOAD_DATA, RENAME_NODES } from '../types/DataTypes';
import { momentToList } from '../../utils/common';

export const editTitle = (id, title) => dispatch => {
    dispatch({type: EDIT_TITLE, payload: { id, title }});
    fetch('/tree/update-title/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({id, title}),
    })
    .then(res => res.json())
    .then(json => {
        console.log('Zmieniono w bazie!')
    })
 };

export const editDescription = () => ({
    type: EDIT_DESCRIPTION,
});

export const addNode = parentId => dispatch => {
    fetch('/tree/add-node/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({parent_id: parentId}),
    })
    .then(res => res.json())
    .then(newId => {
        dispatch({type: ADD_NODE, payload: { parentId, newId }});
        console.log('Dodano node!')
    })
};

export const addNodeAsSibling = siblingId => dispatch => {
    fetch('/tree/add-node-as-sibling/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({sibling_id: siblingId}),
    })
    .then(res => res.json())
    .then(newId => {
        dispatch({type: ADD_NODE_AS_SIBLING, payload: { siblingId, newId }});
        console.log('Dodano node!');
    })
};

export const deleteNode = (id) => dispatch => {
    dispatch({ type: DELETE_NODE, payload: { id } });
    fetch('/tree/delete-node/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ id }),
    })
    .then(res => res.json())
    .then(json => {
        console.log('Usunięto node!')
    })
};

export const copyNode = (data, targetId, remove=false) => dispatch => {
    fetch('/tree/copy-node/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ target_id: targetId, data }),
    })
    .then(res => res.json())
    .then(json => {
        dispatch({ type: COPY_NODE, payload: { data: json, targetId, newId: json[0].id }})
        if (remove) dispatch(deleteNode(data.id));
    })
};

export const loadData = (period={}) => dispatch => {
    const dates = Object.keys(period).length > 0 
    ? { start: momentToList(period.start, true), end: momentToList(period.end, false) }
    : {}
    fetch('/tree/get-tree/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(dates)
    })
    .then(res => res.json())
    .then(json => {
        dispatch({ type: LOAD_DATA, json });
    })
};

export const renameNodes = nodes => dispatch => {
    fetch('/tree/rename-nodes/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(nodes),
    })
    .then(res => res.json())
    .then(json => {
        dispatch({ type: RENAME_NODES, json });
    })
};
