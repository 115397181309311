import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import './LoginForm.css';

const LoginForm = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = e => {
        props.handleLogin(e, { username: username, password: password });
    }

    return (
        <div className="login">
            <form onSubmit={handleSubmit}>
                <h3 className="mb-2">Logowanie</h3>
                <FormGroup controlId="username">
                    <FormLabel className="LoginForm-label">Nazwa użytkownika</FormLabel>
                    <FormControl
                        autoFocus
                        type="text"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                </FormGroup>
                <FormGroup controlId="password">
                    <FormLabel className="LoginForm-label">Hasło</FormLabel>
                    <FormControl
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </FormGroup>
                <p>{ props.status }</p>
                <Button block variant="secondary" type="submit">
                    Zaloguj się
                </Button>
            </form>
        </div>
    );
}

export default LoginForm;

LoginForm.propTypes = {
    handleLogin: PropTypes.func.isRequired
};
