import React from 'react';
import Routing from './components/routing/Routing';
import './App.css';

function App() {
    return (
        <div className="vh-100 pl-3 pr-3">
            <Routing />
        </div>
    );
}

export default App;
