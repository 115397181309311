import React from 'react';

// Helper functions for filtering
export const defaultMatcher = (filterText, node) => {
    const re = new RegExp('\\b' + filterText, 'gi');
    return re.test(node.data.name);
    // return node.data.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
};

export const findNode = (node, filter, matcher) => {
    return matcher(filter, node) || // i match
        (node.children && // or i have decendents and one of them match
            node.children.length &&
            !!node.children.find(child => findNode(child, filter, matcher)));
};

const highlightFoundInChildren = (children, filter, matcher = defaultMatcher) => {
    children.forEach(child => {
        if (matcher(filter, child)) child.data.class = 'found';
        else child.data.class = '';
        if (child.children) highlightFoundInChildren(child.children, filter);
    })
}

export const filterTree = (node, filter, matcher = defaultMatcher) => {
    if (node instanceof Array) {
        const filteredArr = [];
        node.forEach(nd => {
            filteredArr.push(filterTree(nd, filter, matcher));
        })
        return filteredArr;
    } else {
        // If im an exact match then all my children get to stay
        node.data.class = '';
        if (matcher(filter, node)) {
            const data = node.data;
            if (node.children) highlightFoundInChildren(node.children, filter);
            return {...node, data: {...data, name: data.name, class: 'found'}};
        }

        if (!node.children) {
            return {};
        }

        const filtered = node.children
            .filter(child => findNode(child, filter, matcher))
            .map(child => filterTree(child, filter, matcher));
        // If not then only keep the ones that match or have matching descendants
        if (filtered.length === 0) return {};
        return Object.assign({}, node, {children: filtered});
    }
};

export const filterOutEmptyObj = obj => {
    return obj.filter(value => Object.keys(value).length !== 0);
}

export const expandFilteredNodes = (node, filter, matcher = defaultMatcher) => {
    if (node instanceof Array) {
        const expandedArr = [];
        node.forEach(nd => expandedArr.push(expandFilteredNodes(nd, filter, matcher)))
        return expandedArr;
    }
    let children = node.children;
    if (!children || children.length === 0) {
        return Object.assign({}, node, {toggled: false});
    }
    const childrenWithMatches = node.children.filter(child => findNode(child, filter, matcher));
    const shouldExpand = childrenWithMatches.length > 0;
    // If im going to expand, go through all the matches and see if thier children need to expand
    if (shouldExpand) {
        children = childrenWithMatches.map(child => {
            return expandFilteredNodes(child, filter, matcher);
        });
    }
    return Object.assign({}, node, {
        children: children,
        toggled: shouldExpand
    });
};

export const getHeader = node => {
    const classes = [];
    let value = node.data.name;
    if (node.data?.class === 'found') {
        classes.push('found');
    }
    if (node.data.name.endsWith(':')) {
        classes.push('Header-highlight');
        value = node.data.name.substring(0, node.data.name.length - 1);
    }
    if (!node.data.has_values && !node.data.name.endsWith(':')) {
        classes.push('Header-italic');
    }
    if (node.modified) {
        classes.push('Header-modified');
    }
    return <div className={classes.join(' ')}>{value}</div>
}
