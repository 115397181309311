const initialState = {
    listening: false,
}

const listeningReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'START_LISTENING':
            return { listening: true };
        case 'STOP_LISTENING':
            return { listening: false };
        default:
    }

    return state;
}

export default listeningReducer;