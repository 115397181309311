import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import 'moment/locale/pl';
import 'react-dates/lib/css/_datepicker.css';


const PeriodSelector = props => {
    const [period, setPeriod] = useState({ start: null, end: null });
    const [focused, setFocused] = useState(null);

    moment.locale('pl');

    function handleClick() {
        if (period.start && period.end) props.onChange(period);
        else {
            alert('Uzupełnij daty!');
        }
    }

    function handleReset() {
        props.onChange();
        setPeriod({ start: null, end: null });
    }

    return (
        <Row className="mb-2">
            <Col>
                <DateRangePicker
                    startDate={period.start}
                    startDateId="startDate"
                    endDate={period.end}
                    endDateId="endDate"
                    onDatesChange={({ startDate, endDate }) => setPeriod({ start: startDate, end: endDate })}
                    focusedInput={focused}
                    onFocusChange={focusedInput => setFocused(focusedInput)}
                    startDatePlaceholderText="Początek"
                    endDatePlaceholderText="Koniec"
                    isOutsideRange={() => false}
                    small
                />
                <Button variant="dark" size="sm" className="ml-3" onClick={handleClick}>Filtruj</Button>
                <Button variant="dark" size="sm" className="ml-1" onClick={handleReset}>Reset</Button>
            </Col>
        </Row>
    )
}

export default PeriodSelector;
