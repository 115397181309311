import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Authentication from '../auth/Authentication';
import Tree from '../tree/Tree';
import PrivateRoute from './PrivateRoute';
import PublicOnlyRoute from './PublicOnlyRoute';

const Routing = () => (
    <Router>
        <PrivateRoute exact path="/" component={Tree} />
        <PublicOnlyRoute exact path="/login" component={Authentication} />
    </Router>
)

export default Routing;
